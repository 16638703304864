import React from "react"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import ImageSlider from "../components/ImageSlider/ImageSlider"

import SEO from "../components/seo"

const blooddrive = () => {
  const images = ["BOMS/DEV/IMG_3922"]
  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={"en"}>
        <SEO title="Blood Drive" description="Blood Drive" />
        <div className="blooddrive">
          <div className="basic-page blood-drive-page">
            <div className="program-sub-container">
              <img
                src="https://res.cloudinary.com/nuvolum/image/upload/v1624310455/Programs/2021-blood-drive-homepage-banner.jpg"
                alt="2021 Blood Drive"
              />
            </div>
            <div className="back-button"></div>
            <div className="full-section">
              <div className="wrapper">
                <div className="blood-close">
                  <div>
                    <h1
                      style={{
                        fontSize: "2rem",
                        color: "#006cb0",
                        marginTop: "0",
                      }}
                    >
                      Thank You for Giving Blood!
                    </h1>
                    <p>
                      From surgery to fighting cancer and other illnesses,
                      donated blood is used every 2 seconds in the United
                      States.
                    </p>

                    <p>
                      Beacon Oral & Maxillofacial Surgeons partnered with
                      American Red Cross Blood Services for our third community
                      blood drive. Thanks to all our donors, the blood provided
                      will help save up to 48 lives!
                    </p>

                    <p>
                      We thank you for your contribution and look forward to
                      hosting more life-changing events in the future.
                    </p>

                    <p style={{ marginBottom: "0", fontStyle: "italic" }}>
                      Learn more about blood donation and schedule your
                      appointment to be a lifesaver{" "}
                      <a
                        href="https://www.redcrossblood.org/"
                        title="Red Cross Blood: Donate Blood, Platelets, or Plasma"
                        target="_blank"
                      >
                        here
                      </a>
                      .
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="close-w4w-slider">
              <ImageSlider images={images} useDots useArrows adaptiveHeight />
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default blooddrive

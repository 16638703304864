import React from 'react'
import Slider from "react-slick";

import ImageMeta from '../ImageMeta'

import './ImageSlider.scss'

const ImageSlider = ({ images=[], useDots, useArrows, adaptiveHeight }) => {
    const settings = {
        dots: useDots,
        arrows: useArrows,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: adaptiveHeight
    };

    return (
        <div className="image-slider">
            <Slider {...settings}>
                {images.map((image, i) => (
                    <div key={i}>
                        <ImageMeta
                            cloudName="nuvolum"
                            publicId={image}
                            width="auto"
                            responsive
                            responsiveUseBreakpoints="true"
                        />
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default ImageSlider
